import * as React from 'react';
import { Formik, Form, Field, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import HubspotForm from 'react-hubspot-form'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90vw',
        md: '90vw'
    },
    maxWidth: 400,
    bgcolor: 'background.light',
    borderRadius: 4,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center'
};

const initialValues = {
    email: "",
    form: "",
};

const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    form: Yup.string()
});

const FormModal = (props: {
    open: boolean,
    siteId: string,
    formDefinition,
    closeModal
}) => {
    return <Modal
        open={props.open}
        onClose={props.closeModal}
        aria-describedby="modal-modal-description"
    >
        <Stack sx={style} id="modal-modal-description" spacing={2}>
            <Typography variant="h5">
                {props.formDefinition.title}
            </Typography>
            <HubspotForm
                portalId='9431931'
                formId='dc03c3c5-6a94-45df-a375-1fb747cab66c'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
            />
        </Stack>
    </Modal>
}
declare global {
    interface Window {
        gtag_report_conversion: Function | undefined;
    }
}

export default FormModal

