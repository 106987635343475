import * as React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SmallGridTile from "../atoms/SmallGridTile";

const Features = (props) => {
  return (
    <>
      {props.features?.length !== 0 ? (
        <>
                <Stack
            sx={{
              textAlign: "center",
              py: 3,
              px:{lg:9, md: 4}
            }}
          >
          </Stack>
         <Box sx={{flexGrow: 1}}>
         <Grid container spacing={3}>
            {props.features.map((feature, index) => {
              return (
                <SmallGridTile
                key={"feature-" + index}
                  name={feature.name}
                  description={feature.description}
                  image={props.images[index]?.node?.fluid?.src}
                />
              );
            })}
          </Grid>
         </Box>
        </>
      ) : undefined}
    </>
  );
};

export default Features;
