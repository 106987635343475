import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import BigTile from "../atoms/BigTile";

const Products = (props) => {
  return (
    <>
      {props.products?.length !== 0 ? (
        <>
          <Stack
            sx={{
              textAlign: "center",
              py: 3,
              px: { lg: 9, md: 4 },
            }}
          >
            <Typography
              variant="h4"
              component="h5"
              color="primary.light"
              lineHeight={1.5}
            >
              The Whitsundays are known as one of the seven wonders of the world
              for a reason. From exploring waterfalls, to immersing yourself in an underwater
              wonderland of colour.
              <br />
              <br />
              The best way to explore this famous icon is from one of our
              superyachts. Let us take care of everything from luxury amenities on-board
              through to world-class dining and experiences.
            </Typography>
          </Stack>
          {props.products.map((product, index) => {
            return (
              <BigTile
                key={"product-" + index}
                name={product.name}
                link={product.link}
                website={props.website}
                eyebrow={product.eyebrow}
                description={product.description}
                price={product.price}
                priceCaption={product.priceCaption}
                image={props.images[index]?.node?.fluid?.src}
              />
            );
          })}
        </>
      ) : undefined}
    </>
  );
};

export default Products;
