import * as React from "react"
import {Helmet} from "react-helmet"
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import icon from '../images/icon.png'
import MainPage from "../components/MainPage";
import staticData from "../components/utils/StaticData";

let theme = createTheme({
    spacing: 8,
    typography: {
        fontFamily: [
            'system-ui',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontWeight: "Bold"
        },
        h2: {
            fontWeight: "Bold"
        },
        h3: {
            fontWeight: "Bold"
        },
        h4: {
            fontWeight: "Bold"
        },
        h5: {
            fontWeight: "Bold"
        },
        fontSize: 14,
    },
    palette: {
        // This has to be applied via global/styles.css Why?
        background: {
            light: '#FFFFFF',
            main: '#FAFAFA'
        },
        primary: {
            main: '#1a345b'
        },
        secondary: {
            main: '#02bd9b'
        },
    }
});

theme = responsiveFontSizes(theme);

const IndexPage = () => {
    const data = staticData()
    return (<>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="description" content={data.brand.briefDescription}/>
                <link rel="icon" type="image/png" href={icon} sizes="16x16"
                />
                <title>{data.brand.name}</title>
            </Helmet>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {/*Do not know why default background does not work as expected */}
                <MainPage data={data}/>
            </ThemeProvider>
        </>
    )
}

export default IndexPage
