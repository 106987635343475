import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SideBySideTile from "../atoms/SideBySideTile";

const Steps = (props) => {
  return (
    <>
      {props.steps?.length !== 0 ? (
        <>
          <Stack
            sx={{
              textAlign: "center",
              py: 3,
              px:{lg:9, md: 4}
            }}
          >
             <Typography variant="h4" component="h2" color="primary.light"  lineHeight={1.5}>
              Ahoy Club is the #1 digital yacht charter platform.
              <br />
              <br />
              Over 4,000 professionally crewed charter yachts around the world. For the best possible price.
            </Typography>
          </Stack>
          {props.steps.map((step, index) => {
            return (
              <SideBySideTile
                key={"step-" + index}
                name={step.name}
                eyebrow={step.eyebrow}
                description={step.description}
                price={step.price}
                priceCaption={step.priceCaption}
                image={props.images[index]?.node?.fluid?.src}
                index={index}
              />
            );
          })}
        </>
      ) : undefined}
    </>
  );
};

export default Steps;
