import * as React from "react";
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import LeadButton from "../atoms/LeadButton";

const Footer = (props) => {
    return <>
        <Stack sx={{
            textAlign: 'center',
            backgroundColor: 'primary.main',
            justifyContent: "center",
                    alignItems: "center",
            color: 'background.light',
            pt:8,
            pb:4
        }} 
            spacing={12}
        >
                    <Typography variant='h3'
                                color='background.light'
                    >
                        {props.website?.live?.functions?.form?.bottomHeader}
                    </Typography>
                    <LeadButton website={props.website} color='secondary'/>
                    <Container>
            <Stack direction="row" justifyContent="space-between"
  alignItems="center">
                  <Typography variant="caption" component="p" color='background.light'>{props.brand.name} © 2022</Typography>
            <Typography variant={'subtitle1'}>
                        Made with <Link href={'https://tryevergrow.com'}
                                        target="_blank"
                                        rel="noopener"
                                        fontWeight="bold"
                                        underline="none"
                                        color="inherit"
                        >Evergrow</Link>
                    </Typography>
            </Stack>
            </Container>
        </Stack>
       
    </>
}

export default Footer