import * as React from "react";
import {useState} from "react";
import Box from "@mui/material/Box";
import LeadButton from "./LeadButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import RightIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import FormModal from "../organisms/FormModal";


const BigTile = (props) => {
  const [openModal, setOpenModal] = useState(false)
  return (
    <Stack
      sx={{
        backgroundColor: "background.light",
        display: "inline",
        minHeight: "25vh",
        borderRadius: 4,
        padding: 4,
        boxShadow: `0 3px 24px rgb(0 0 0 / .02)`,
      }}
      spacing={3}
    >
      
      <Stack spacing={1}>
        <Box>
          <Box>
            {props.eyebrow ? (
              <Typography variant="h6" fontWeight="bold" color="secondary.main">
                {props.eyebrow}
              </Typography>
            ) : null}
            <Typography variant="h3">{props.name}</Typography>
          </Box>
          <Box>
            {props.price ? (
              <Typography variant="h6" fontWeight="bold" color="primary.light">
                {props.price}
              </Typography>
            ) : null}
            {props.priceCaption ? (
              <Typography variant="subtitle2" color="primary.light">
                {props.priceCaption}
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Typography variant="h6" component="p" fontWeight={400}>
          {props.description}
        </Typography>
        <Stack direction={"row"} spacing={2}>
            <Box>
            <Button
                    key={'button'}
                    size="large"
                    sx={{
                        fontWeight: "bold",
                        backgroundColor: "#02bd9b",
                        color:"#FFFFFF",
                        borderRadius: 10,
                        px:2,
                        "&:hover": { backgroundColor: "", color:"#02bd9b" } 
                    }}
                    onClick={() => {
                      setOpenModal(true)
                    }}
                    variant="text"
                >
                    Enquire now
                </Button>
            </Box>
        {props.link? <Box>
            <Button
                    key={'button'}
                    size="large"
                    sx={{
                        fontWeight: "bold",
                        px:0,
                        "&:hover": { backgroundColor: "transparent", color:"#02bd9b" } 
                    }}
                    onClick={() => {
                        window.open(props.link, '_blank');
                    }}
                    endIcon={<RightIcon />}
                    variant="text"

                >
                    View specs
                </Button>
        </Box>: null}
        </Stack>
      </Stack>
      {props.image ? (
        <>
          <Box
            sx={{
              maxHeight: "816px",
              overflow: "hidden",
            }}
          >
            <img src={props.image} alt={props.name} width="100%" />
          </Box>
        </>
      ) : null}
      <FormModal
                open={openModal}
                siteId={props.website.jsonId}
                formDefinition={props.website?.live?.functions?.form}
                closeModal={() => {
                    setOpenModal(false) 
                }}
            />
    </Stack>
  );
};

export default BigTile;
