import * as React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ImageOr = (props) => {
    return <Box sx={{
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        textAlign: 'center',
        minHeight: "280px",
        maxHeight: "544px",
        overflow:"hidden"
    }}>{
        props.image ?


            <img
                src={props.image}
                alt={props.name}
                width="100%"
            /> :
            <Typography
                variant='h1' component='span'
                color="primary.main"
            >
                {props.index + 1}
            </Typography>
    }
    </Box>
}

const Text = (props) => {
    return <Stack spacing={1}>
        <Box>
            {props.eyebrow ? (
          <Typography variant="h6" fontWeight="bold" color="secondary.main">
            {props.eyebrow}
          </Typography>
        ) : null}
        <Typography
            variant='h3'
            color="primary.main"
        >
            {props.name}
        </Typography>
        </Box>
        <Box>
        {props.price ? (
          <Typography variant="h6" fontWeight="bold" color="primary.main">
            {props.price}
          </Typography>
        ) : null}
        {props.priceCaption ? (
          <Typography variant="subtitle2" color="primary.light">
            {props.priceCaption}
          </Typography>
        ) : null}
        </Box>
        <Typography
            variant='h6'
            component='p'
            fontWeight={400}
        >
            {props.description}
        </Typography>
    </Stack>;
}

const SideBySideTile = (props) => {
    return <>
        <Box sx={{
            display: {xs: 'none', sm: 'grid'},
            gridTemplateColumns: 'repeat(2, 1fr)',
            mb: 4,
            p: 4,
            borderRadius: 4,
            backgroundColor: 'background.light',
            columnGap: 3,
            rowGap: 1,
            boxShadow: `0 3px 24px rgb(0 0 0 / 0.02)`
        }}>
            {props.index % 2 === 0 ?
                <ImageOr image={props.image} name={props.name} index={props.index}/> :
                <Text name={props.name} description={props.description} price={props.price} eyebrow={props.eyebrow} priceCaption={props.priceCaption}/>
            }

            {props.index % 2 === 0 ?
                <Text name={props.name} description={props.description} price={props.price} eyebrow={props.eyebrow} priceCaption={props.priceCaption}/> :
                <ImageOr image={props.image} name={props.name} index={props.index}/>
            }
        </Box>
        <Box sx={{
            display: {xs: 'grid', sm:'none'},
            gridTemplateColumns: 'repeat(1, 1fr)',
            mb: 4,
            p: 4,
            borderRadius: 4,
            backgroundColor: 'background.light',
            columnGap: 3,
            rowGap: 1,
        }}>
            <ImageOr image={props.image} name={props.name} index={props.index}/>
            <Text name={props.name} description={props.description} price={props.price} eyebrow={props.eyebrow} priceCaption={props.priceCaption}/>
        </Box>
    </>
}

export default SideBySideTile