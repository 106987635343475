import * as React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LeadButton from "./LeadButton";
import icon from "../../images/logo.png";

const Header = (props) => {
  return (
    <Box
      sx={{
        background: props.coverImage?.node?.fluid?.src
          ? "linear-gradient( rgba(255, 255, 255, .1), rgba(0, 0, 0, .4) ), url(" +
            props.coverImage?.node?.fluid?.src +
            ")"
          : "block",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
        display: { md: "flex" },
        justifyContent: "center",
        backgroundColor: "background.main",
        color: props.coverImage?.node?.fluid?.src
          ? "primary.main"
          : "primary.main",
          textShadow:"1px 1px 9px rgba(0, 0, 0, .25)"
      }}
    >
      <Container
        sx={{
          py: 4,
          maxHeight: "700px"
        }}
      >
        <Stack direction={"row"} spacing={1} alignItems="center">
          {props.brand.logo && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={1}
              borderRadius={100}
            >
              <a href="https://www.ahoyclub.com?utm_source=evergrow" alt="Ahoy Club" target="blank"><img src={icon} alt={props.brand.name} height={"32px"} /></a>
            </Box>
          )}
        </Stack>
        <Stack
          sx={{
            pt: { xs: 12, sm: 10 },
            pb: 4,
            px: { lg: 8 },
            textAlign: "center",
          }}
          spacing={2}
        >
          <Typography variant="h1"  color="background.light">{props.brand.briefDescription}</Typography>

          <Typography
            variant="h4"
            fontWeight={"medium"}
            color="background.light"
            sx={{ px: { md: 7, lg: 8 } }}
          >
            {props.brand.longDescription}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: 4,
            }}
          >
            <LeadButton
              website={props.website}
              color={
                props.coverImage?.node?.fluid?.src ? "background" : "primary"
              }
            />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Header;